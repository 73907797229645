import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card m-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-4 col-md-3 col-xl-2 m-3" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "fa-solid fa-user fa-3x",
  style: {"width":"250px","height":"250px","display":"flex","justify-content":"center","align-items":"center"},
  "aria-hidden": "true"
}
const _hoisted_6 = { class: "col-sm-8 col-md-8 col-xl-8" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "card-title mb-3" }
const _hoisted_9 = {
  key: 0,
  class: "badge bg-danger rounded-pill"
}
const _hoisted_10 = {
  key: 1,
  class: "badge bg-success rounded-pill"
}
const _hoisted_11 = {
  key: 2,
  class: "dropdown m-2"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "nav nav-tabs"
}
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "nav-item" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "nav-item" }
const _hoisted_18 = { class: "nav-item" }

import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { createApp, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { Popover, Tooltip } from "bootstrap";
import AffectationIndividuGroupe from "@/composant/AffectationIndividuGroupe.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuProfil',
  setup(__props) {

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()
const storeGroupeIndividu = useGroupeIndividuStore()

const isIndividuConnecte = ref(false)
const popovers = ref<Popover[]>([])

const bloque = (): boolean => {
  if (storeGroupeIndividu.listeGroupeIndividu !== null) {
    return storeGroupeIndividu.listeGroupeIndividu
      .filter(groupe => groupe.bloque === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === storeIndividuPresentation.individuSelectionne.id));
  }
}

const abonnementAutomatique = ():boolean =>{
  if (storeGroupeIndividu.listeGroupeIndividu !== null) {
    return storeGroupeIndividu.listeGroupeIndividu
      .filter(groupe => groupe.abonnementAutomatique === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === storeIndividuPresentation.individuSelectionne.id));
  }
}
onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
    await storeGroupeIndividu.rechercherListe()


    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))


    await nextTick(() => {
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
        const popoverContentEl = document.createElement('div');
        const app = createApp(AffectationIndividuGroupe, { individu: storeIndividuPresentation.individuSelectionne });
        app.mount(popoverContentEl);
        const popover = new Popover(popoverTriggerEl, {
          html: true,
          content: popoverContentEl,
          trigger: "manual"
        });
        // Stocker le popover et le bouton déclencheur
        popovers.value.push(popover);

        let popoverTimeout: number;

        // Fonction pour démarrer ou prolonger le délai
        const startPopoverTimer = () => {
          clearTimeout(popoverTimeout); // Annule tout timer actif
          popoverTimeout = setTimeout(() => popover.hide(), 3000); // Fermeture après 3 secondes
        };

        // Ouvre le popover et démarre le délai lors du clic sur le déclencheur
        popoverTriggerEl.addEventListener('click', () => {
          clearTimeout(popoverTimeout);
          popover.show();
          startPopoverTimer();
        });

        // Prolonge le délai lorsqu'on clique dans le contenu du popover
        popoverContentEl.addEventListener('click', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        popoverContentEl.addEventListener('over', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        // Nettoie le timer si le popover est fermé manuellement
        popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
          clearTimeout(popoverTimeout);
        });
      })
    });
  }
  catch (error){
    console.error(error);
  }
});



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_unref(storeIndividuPresentation).individuSelectionne?.imagePresentation)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _unref(storeIndividuPresentation).individuSelectionne?.imagePresentation,
                style: {"width":"250px"},
                class: "img-fluid rounded",
                alt: "Image de profil"
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createElementBlock("i", _hoisted_5))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h5", _hoisted_8, _toDisplayString(_unref(storeIndividuPresentation).individuSelectionne?.pseudonyme), 1),
            (bloque())
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Liste noire"))
              : _createCommentVNode("", true),
            (abonnementAutomatique())
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Abonnements"))
              : _createCommentVNode("", true),
            (_unref(storeIndividuConnecte).individuConnecte?.id!=_unref(storeIndividuPresentation).individuSelectionne?.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[0] || (_cache[0] = [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-primary",
                    "data-bs-toggle": "popover",
                    "data-bs-title": "Affectez des groupes"
                  }, [
                    _createTextVNode(" Groupes "),
                    _createElementVNode("i", {
                      class: "fa fa-plus",
                      "aria-hidden": "true"
                    })
                  ], -1)
                ])))
              : _createCommentVNode("", true),
            _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("p", {
              class: "card-text",
              innerHTML: _unref(storeIndividuPresentation).individuSelectionne?.presentation
            }, null, 8, _hoisted_12)
          ])
        ])
      ])
    ]),
    (!bloque())
      ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
          _createElementVNode("li", _hoisted_14, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/texte/'
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Textes")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_15, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/lecture/'
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Lectures")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_16, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/atelier/'
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Ateliers")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_17, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/discussion/'
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Discussions")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_18, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: '/individu/'+_unref(id)+'/bibliotheque/'
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("Bibliothèque")
              ])),
              _: 1
            }, 8, ["to"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}
}

})