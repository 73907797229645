import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-group col-auto" }
const _hoisted_2 = { class: "form-group col-auto" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-primary",
  role: "alert"
}
const _hoisted_4 = {
  key: 1,
  class: "alert alert-warning",
  role: "alert"
}
const _hoisted_5 = {
  key: 2,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_6 = ["disabled"]

import { Ref, ref, watch } from "vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'DemandeCreationCompte',
  setup(__props) {

const afficherCreationOk = ref(false);
const afficherCreationEchec = ref(false);
const isErreurCreation = ref(false);
const messageErreurCreation = ref("")
const isDateValide = ref(false);
const courriel = ref("");
const isCourrielValide = ref(false);
const isValitationEnabled = ref(true);
const gestionCompteStore = useGestionCompteStore()

const moinsQuinzeAns = () => {
  let maintenant = new Date();
  maintenant.setHours(0, 0, 0, 0);
  return new Date(maintenant.getTime() - 15 * 365 * 24 * 3600 * 1000)
}
const dateNaissance: Ref<null | string> = ref(null)
const isPlusQuinzeAns = () => {
  const dateNaiss = new Date(dateNaissance.value);
  if(dateNaiss < new Date('01-01-1900')) return false;
  return dateNaiss < new Date(moinsQuinzeAns());
}
const evaluationEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  isCourrielValide.value = emailPattern.test(courriel.value) && courriel.value.length < 100;
}
const evaluationDate = () => {
  isDateValide.value = dateNaissance.value!=null && isPlusQuinzeAns()
}

const creation = async () => {
  isValitationEnabled.value = false
  afficherCreationOk.value = false
  if(isDateValide.value && isCourrielValide.value && dateNaissance.value!=null) {
    const resultat = await gestionCompteStore.creationPreIndividu(courriel.value, dateNaissance.value);
    if (resultat.erreur != null) {
      isErreurCreation.value = true
      afficherCreationOk.value = false
      afficherCreationEchec.value = false
      messageErreurCreation.value = resultat.erreur.message
    } else if (!resultat.reussi) {
      afficherCreationOk.value = false
      afficherCreationEchec.value = true
    } else {
      afficherCreationOk.value = true
      afficherCreationEchec.value = false
    }
  }
  isValitationEnabled.value= true
};
watch(dateNaissance, evaluationDate)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _withModifiers(creation, ["prevent"]),
    class: "row g-3 needs-validation",
    novalidate: ""
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        class: "form-label",
        for: "courriel"
      }, "Entrez votre email", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "courriel",
        class: _normalizeClass(["form-control", isCourrielValide.value? 'is-valid':'is-invalid']),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
        type: "email",
        required: "",
        onInput: evaluationEmail
      }, null, 34), [
        [_vModelText, courriel.value]
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "valid-feedback" }, " Courriel bien formé ", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "invalid-feedback" }, " Courriel mal formé ou vide ", -1))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("label", null, "Entrez votre date de naissance, vous devez avoir 15 ans minimum", -1)),
      _withDirectives(_createElementVNode("input", {
        id: "dateNaissance",
        class: _normalizeClass(["form-control", isDateValide.value? 'is-valid':'is-invalid']),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dateNaissance).value = $event)),
        type: "date",
        required: "",
        onInput: evaluationDate
      }, null, 34), [
        [_vModelText, dateNaissance.value]
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "valid-feedback" }, " C'est bon vous avez plus de quinze ans ", -1)),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "invalid-feedback" }, " La majorité numérique est recquise ", -1)),
      (afficherCreationOk.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Le courriel est créé "))
        : _createCommentVNode("", true),
      (afficherCreationEchec.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " L'e-mail saisit existe déjà dans nos bases "))
        : _createCommentVNode("", true),
      (isErreurCreation.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(messageErreurCreation.value), 1))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-primary",
        disabled: !isValitationEnabled.value || !isDateValide.value || !isCourrielValide.value
      }, "Créer le compte", 8, _hoisted_6)
    ])
  ], 32))
}
}

})