<template>
  <div class="contenant-principal">
    <!-- Alerte placée au-dessus du formulaire de connexion -->
    <div class="alert alert-danger text-center placard" role="alert">!!! Site en cours de construction !!! </div>

    <!-- Conteneur de la fenêtre de connexion centrée -->
    <div class="contenant">
      <div class="card contenu" title="Connexion">
        <div class="card-body">
          <h1 class="card-title">Connexion</h1>
          <form @submit.prevent="connexion">
            <div class="mb-3">
              <div class="form-group col-auto">
                <label for="courriel">Courriel</label>
                <input id="courriel" type="email" class="form-control"
                       v-model="courriel" placeholder="mon.courriel@example.com">
              </div>
              <div class="form-group col-auto">
                <label for="password">Mot de passe</label>
                <div class="password-container">
                  <div class="input-group">
                    <input id="password" :type="montrePasswordClair ? 'text' : 'password'"
                           v-model="password"
                           class="form-control" placeholder="Mot de passe" >
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" @click="changeVisibilitePassword">
                        <i class="fas fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="gestionCompteStore.estErreurConnexion" class="alert alert-danger" role="alert">
                {{ gestionCompteStore?.erreurConnexion?.message }}
              </div>
              <button type="submit" class="btn btn-primary">Connexion</button>
            </div>
          </form>
          <p>
            <button class="btn btn-link" data-bs-toggle="modal" data-bs-target="#demandeCreationCompteModal">Créer un compte
            </button>
          </p>
          <p>
            <button class="btn btn-link" data-bs-toggle="modal" data-bs-target="#demandeRecuperationCompteModal">J'ai oublié mon mot de passe</button>
          </p>
          <!-- Modal de demande de création de compte -->
          <div id="demandeCreationCompteModal" class="modal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Création de compte</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <DemandeCreationCompteComposant />
                </div>
              </div>
            </div>
          </div>
          <!-- Modal de demande de récupération de compte -->
          <div id="demandeRecuperationCompteModal" class="modal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Récupération de compte</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <DemandeRecuperationCompte />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import DemandeCreationCompteComposant from "@/pages/DemandeCreationCompte.vue";
import DemandeRecuperationCompte from "@/pages/DemandeRecuperationCompte.vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";

const router = useRouter();
const courriel = ref("");
const password = ref("");
const montrePasswordClair = ref(false)


const individuConnectestore = useIndividuConnecteStore()
const gestionCompteStore = useGestionCompteStore()
const isCreationModalVisible = ref(false);
const isRecuperationModalVisible = ref(false);

const changeVisibilitePassword = () =>
  montrePasswordClair.value = !montrePasswordClair.value;
const showCreationModal = () => {
  isCreationModalVisible.value = true;
};
const showRecuperationModal = () => {
  isRecuperationModalVisible.value = true;
};
const connexion = async () => {
  await gestionCompteStore.connexion(courriel.value, password.value);
  await individuConnectestore.connecterIndividu()
  if (individuConnectestore.individuConnecte != null) {
    await router.push({ path: '/' });
  }
}
</script>


<style scoped lang="scss">
.contenant-principal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Prend 100% de la hauteur de la vue */
  background-image: url('@/assets/background.jpg');
  background-size: cover; /* Ajuste l'image pour couvrir tout l'élément */
  background-position: center; /* Centre l'image dans l'élément */
  background-repeat: no-repeat; /* Évite que l'image se répète */
}

.placard {
  width: 80%; /* S'assure que l'alerte prend toute la largeur disponible */
}

.contenant {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contenu {
  width: 25rem; /* Largeur fixe de la carte */
}
</style>

