import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import * as zxcvbnEnPackagefr from '@zxcvbn-ts/language-fr'

export class ConfigVerifPassword {

  options  = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
      ...zxcvbnEnPackagefr.dictionary
    }
  }

  isPasswordValid (password: string) {
    if (password.length>50) return false;
    zxcvbnOptions.setOptions(this.options)
    return zxcvbn(password).score==4;
  }

}