<template>
  <div class="card m-3">
    <div class="card-body">
      <h1 class="card-title">Informations privées</h1>
      <div>Date de création du compte : <span>{{ formattedInstantcreation }}</span></div>
      <div>Date de naissance : <span>{{ formattedDateNaissance }}</span></div>
      <div v-if="individuDonneePriveStore.individuDonneePriveConnecte?.actif">
        votre compte est actif
      </div>
      <div v-else>Votre compte a été désactivé. En voici la raison :
        {{ individuDonneePriveStore.individuDonneePriveConnecte?.justificationInvalidation }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIndividuDonneePriveStore } from "@/store/individu-donne-prive-store";

const individuDonneePriveStore = useIndividuDonneePriveStore()
const individuConnecteStore = useIndividuConnecteStore()
const formattedInstantcreation = ref<string | null>(null);
const formattedDateNaissance = ref<string | null>(null);
import { onMounted, ref } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";

onMounted(async () => {
  if (individuConnecteStore.individuConnecte) {
    await individuDonneePriveStore.rechercherById(individuConnecteStore.individuConnecte?.id)
    if (individuDonneePriveStore.individuDonneePriveConnecte) {
      formattedInstantcreation.value = new Date(individuDonneePriveStore.individuDonneePriveConnecte.instantCreation).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      formattedDateNaissance.value = new Date(individuDonneePriveStore.individuDonneePriveConnecte.dateNaissance).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

    }
  }
})
</script>

<style scoped lang="scss">

</style>