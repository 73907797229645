export class Individu {
  id: number;
  courriel: string;
  pseudonyme: string;
  lstRole: string[];


  constructor(id: number, courriel: string, pseudonyme: string, lstRole: string[]) {
    this.id = id;
    this.courriel = courriel;
    this.pseudonyme = pseudonyme;
    this.lstRole = lstRole;
  }
}

export class IndividuDonneePrive {
  id: number;
  dateNaissance: Date;
  actif: boolean;
  justificationInvalidation: string;
  instantCreation: Date;


  constructor(id: number, dateNaissance: Date, actif: boolean, justificationInvalidation: string, instantCreation: Date) {
    this.id = id;
    this.dateNaissance = dateNaissance;
    this.actif = actif;
    this.justificationInvalidation = justificationInvalidation;
    this.instantCreation = instantCreation;
  }
}

export class IndividuPresentation {
  id: number;
  pseudonyme: string;
  instantCreation: Date;
  actif: boolean;
  presentation: string;
  imagePresentation;

  constructor(id: number, pseudonyme: string, instantCreation: Date, actif: boolean, presentation: string, imagePresentation: string) {
    this.id = id;
    this.pseudonyme = pseudonyme;
    this.instantCreation = instantCreation;
    this.actif = actif;
    this.presentation = presentation;
    this.imagePresentation = imagePresentation;
  }

  static fromJson(data: any): IndividuPresentation {
    return new IndividuPresentation(
      data.id,
      data.pseudonyme,
      data.instantCreation,
      data.actif,
      data.presentation,
      data.imagePresentation
    );
  }

}


export class GroupeIndividu {
  id: null | number;
  libelle: string;
  bloque: boolean;
  abonnementAutomatique: boolean
  listeMembre: IndividuPresentation[];


  constructor(id: number | null, libelle: string, bloque: boolean, abonnementAutomatique: boolean, listeMembre: IndividuPresentation[]) {
    this.id = id;
    this.libelle = libelle;
    this.listeMembre = listeMembre;
    this.bloque = bloque;
    this.abonnementAutomatique = abonnementAutomatique;
  }
}



