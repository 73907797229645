<template>
  <ul class="list-group" role="tablist">
    <li v-for="gr in groupeIndividuStore.listeGroupeIndividu" :key="gr.id"
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
      {{ gr.libelle }}
      <span v-if="gr.bloque" class="badge bg-danger rounded-pill">Liste noire</span>
      <span v-if="gr.abonnementAutomatique" class="badge bg-success rounded-pill">Abonnements</span>
      <div class="form-check form-switch">
        <input class="form-check-input"
               :checked="mapGroupeAppartenance.get(gr.id)"
               type="checkbox" id="flexSwitchCheckDefault"
               @click.prevent="toggleIndividuDansGroupe(gr)">
      </div>
    </li>
  </ul>
</template>

<script lang="ts">


import { defineComponent, onMounted, ref } from "vue";
import { GroupeIndividu, IndividuPresentation } from "@/classes/individu";
import { useGroupeIndividuStore } from "@/store/groupe-individu-store";

export default defineComponent({
  props: {
    individu: {
      type: IndividuPresentation,
      required: true
    }
  },
  setup(props) {
    const groupeIndividuStore = useGroupeIndividuStore();

    const mapGroupeAppartenance = ref<Map<number, boolean>>(new Map());

    const calculPresenceGroupe = () => {
      groupeIndividuStore.listeGroupeIndividu?.forEach((groupe: GroupeIndividu) => {
        if (groupe.id && groupeIndividuStore.listeGroupeIndividu!=null) {
          // Utiliser groupe.listeMembre pour vérifier si l'individu est membre
          const estMembre = groupe.listeMembre.some((ind) => ind.id === props.individu.id);
          mapGroupeAppartenance.value.set(groupe.id, estMembre);
        }
      });
    };

    const toggleIndividuDansGroupe = async (groupe: GroupeIndividu) => {
      if(groupe.id!=null) {
        if (mapGroupeAppartenance.value.get(groupe.id) === true) {
          await groupeIndividuStore.supprimerMembre(groupe,props.individu)
        } else if (mapGroupeAppartenance.value.get(groupe.id) === false) {
          await groupeIndividuStore.ajouterMembre(groupe, props.individu)
        }
        calculPresenceGroupe()
      }
    }
    onMounted(async () => {
      await groupeIndividuStore.rechercherListe()
      console.log(groupeIndividuStore.listeGroupeIndividu)
      calculPresenceGroupe()
    });
    return {
      mapGroupeAppartenance,
      toggleIndividuDansGroupe,
      groupeIndividuStore
    };
  }
});
</script>


<style scoped lang="scss">

</style>