<template>
  <div>
    <div id="toolbar">
      <select class="ql-header">
        <option value="1">Titre 1</option>
        <option value="2">Titre 2</option>
        <option value="3">Titre 3</option>
        <option value="" selected>Normal</option>
      </select>
      <select class="ql-font">
        <option value="sans-serif" selected >Sans Serif</option>
        <option value="serif">Serif</option>
        <option value="monospace">Monospace</option>
        <option value="gothique">Gothique</option>
      </select>
      <select class="ql-size">
        <option value="small">Petit</option>
        <option value="" selected>Moyen</option>
        <option value="large">Grand</option>
        <option value="huge">Énorme</option>
      </select>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>
      <button class="ql-align" value="justify"></button>
      <button class="ql-bolt"></button>
    </div>
    <div id="editeur-quill" class="editor-container"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from 'vue';
import Quill from 'quill';

const Font = Quill.import('formats/font') as any;
Font.whitelist = ['serif', 'sans-serif', 'monospace', 'gothique'];
Quill.register(Font, true);

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    let editor: any = null;

    // Initialisation de Quill après le montage du composant
    onMounted(() => {
      editor = new Quill('#editeur-quill', {
        modules: {
          toolbar: '#toolbar'
        },

        theme: 'snow',
        formats: ['bold', 'underline', 'header', 'italic', 'size', 'align', 'font']
      });

      editor.root.innerHTML = props.modelValue;

      editor.on('text-change', () => {
        const editorContent = editor.root.innerHTML;
        emit('update:modelValue', editorContent ? editorContent : ''); // Émet l'événement pour mettre à jour `v-model`
      });
    });


    // Surveille les modifications de `modelValue` depuis l'extérieur du composant (synchronisation bidirectionnelle)
    watch(() => props.modelValue, (newValue) => {
      if (editor && editor.root.innerHTML !== newValue) {
        editor.root.innerHTML = newValue;
      }
    });
  }
});
</script>
<style lang="scss">
.ql-bubble .ql-tooltip {
  z-index: 1;
}

.ql-container {
  font-family: var(--bs-font-sans-serif);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
}

.ql-font .ql-picker-item[data-value="gothique"]::before,
.ql-font .ql-picker-label[data-value="gothique"]::before {
  font-family: 'Mirage Gothic';
}
.ql-font .ql-picker-item[data-value="serif"]::before,
.ql-font .ql-picker-label[data-value="serif"]::before {
  font-family: 'Garamond' !important;
}
</style>

