import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card m-3" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { useIndividuDonneePriveStore } from "@/store/individu-donne-prive-store";

import { onMounted, ref } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuConfigurationDonneePrive',
  setup(__props) {

const individuDonneePriveStore = useIndividuDonneePriveStore()
const individuConnecteStore = useIndividuConnecteStore()
const formattedInstantcreation = ref<string | null>(null);
const formattedDateNaissance = ref<string | null>(null);
onMounted(async () => {
  if (individuConnecteStore.individuConnecte) {
    await individuDonneePriveStore.rechercherById(individuConnecteStore.individuConnecte?.id)
    if (individuDonneePriveStore.individuDonneePriveConnecte) {
      formattedInstantcreation.value = new Date(individuDonneePriveStore.individuDonneePriveConnecte.instantCreation).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      formattedDateNaissance.value = new Date(individuDonneePriveStore.individuDonneePriveConnecte.dateNaissance).toLocaleString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "card-title" }, "Informations privées", -1)),
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createTextVNode("Date de création du compte : ")),
        _createElementVNode("span", null, _toDisplayString(formattedInstantcreation.value), 1)
      ]),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createTextVNode("Date de naissance : ")),
        _createElementVNode("span", null, _toDisplayString(formattedDateNaissance.value), 1)
      ]),
      (_unref(individuDonneePriveStore).individuDonneePriveConnecte?.actif)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " votre compte est actif "))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, "Votre compte a été désactivé. En voici la raison : " + _toDisplayString(_unref(individuDonneePriveStore).individuDonneePriveConnecte?.justificationInvalidation), 1))
    ])
  ]))
}
}

})