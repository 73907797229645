import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-3"
}
const _hoisted_3 = {
  key: 2,
  class: "dropdown"
}
const _hoisted_4 = {
  class: "btn btn-secondary dropdown-toggle",
  href: "#",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_5 = { class: "dropdown-menu" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "badge bg-danger rounded-pill"
}
const _hoisted_8 = {
  key: 1,
  class: "badge bg-success rounded-pill ms-1"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col col-sm-4 col-md-3" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "fa-solid fa-user fa-3x",
  style: {"width":"50px","height":"50px","display":"flex","justify-content":"center","align-items":"center"},
  "aria-hidden": "true"
}
const _hoisted_13 = { class: "col-sm-8 col-md-6 limite-hauteur" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "col col-sm-1 col-md-1"
}
const _hoisted_16 = { class: "dropdown m-2" }
const _hoisted_17 = ["data-ind"]
const _hoisted_18 = {
  key: 0,
  class: "badge bg-danger rounded-pill"
}
const _hoisted_19 = {
  key: 1,
  class: "badge bg-success rounded-pill"
}

import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { createApp, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { Popover, Tooltip } from 'bootstrap';
import AffectationIndividuGroupe from "@/composant/AffectationIndividuGroupe.vue";
import { GroupeIndividu, IndividuPresentation } from "@/classes/individu";


export default /*@__PURE__*/_defineComponent({
  __name: 'RechercheAuteur',
  setup(__props) {

const individuPresentationStore = useIndividuPresentationStore()
const individuConnecteStore = useIndividuConnecteStore()
const groupeIndividuStore = useGroupeIndividuStore()
const listeIndividuPresentation = ref<IndividuPresentation[] | null>(null);
const popovers = ref<Popover[]>([])

const groupeSelectionne = ref<GroupeIndividu | null>(null)




const selectionnerGroupe = async (gr :GroupeIndividu|null) => {
  groupeSelectionne.value = gr
  if (groupeSelectionne.value !== null) {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee(groupeSelectionne.value);
  } else {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee()
  }
  await initialisationPopover()
}

const bloque = (individu: IndividuPresentation) => {
  if (groupeIndividuStore.listeGroupeIndividu != null) {
    return groupeIndividuStore.listeGroupeIndividu
      .filter(groupe => groupe.bloque === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === individu.id));
  }
}
const abonnementAutomatique = (individu: IndividuPresentation) => {
  if (groupeIndividuStore.listeGroupeIndividu != null) {
    return groupeIndividuStore.listeGroupeIndividu
      .filter(groupe => groupe.abonnementAutomatique === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === individu.id));
  }
}

const initialisationPopover = async () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

  await nextTick(() => {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
      const indJson = popoverTriggerEl.getAttribute('data-ind');
      if (indJson) {
        const ind = IndividuPresentation.fromJson(JSON.parse(indJson));
        const popoverContentEl = document.createElement('div');
        const app = createApp(AffectationIndividuGroupe, { individu: ind });
        app.mount(popoverContentEl);

        const popover = new Popover(popoverTriggerEl, {
          html: true,
          content: popoverContentEl,
          trigger: "manual"
        });
        popovers.value.push(popover);

        let popoverTimeout: number;

        // Fonction pour démarrer ou prolonger le délai
        const startPopoverTimer = () => {
          clearTimeout(popoverTimeout); // Annule tout timer actif
          popoverTimeout = setTimeout(() => popover.hide(), 3000); // Fermeture après 3 secondes
        };

        // Ouvre le popover et démarre le délai lors du clic sur le déclencheur
        popoverTriggerEl.addEventListener('click', () => {
          clearTimeout(popoverTimeout);
          popover.show();
          startPopoverTimer();
        });

        // Prolonge le délai lorsqu'on clique dans le contenu du popover
        popoverContentEl.addEventListener('click', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        popoverContentEl.addEventListener('over', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        // Nettoie le timer si le popover est fermé manuellement
        popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
          clearTimeout(popoverTimeout);
        });
      }

      // Initialiser les tooltips sur les boutons
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, { trigger: 'focus' }));
    });
  });
};

onMounted(async () => {
  await individuConnecteStore.connecterIndividu()
  await groupeIndividuStore.rechercherListe()
  if (individuConnecteStore.individuConnecte) {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee()
  } else {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPublique()
  }

  await initialisationPopover()

  // Nettoyer lors du démontage du composant
  onBeforeUnmount(() => {
    popovers.value.forEach(popover => popover.dispose()); // Dispose des popovers
  });

})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(individuConnecteStore).individuConnecte)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_unref(groupeIndividuStore).listeGroupeIndividu?.length===0)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "link-info",
                  to: '/individu/configuration/groupe/',
                  "data-bs-toggle": "tooltip",
                  "data-bs-title": "Les groupes vous permettrons de sélectionner des listes d'auteurs,\n                  de gérer les droits que vous souhaitez leur affecter sur vos œuvre ou s'abonner"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Créez des groupes de personnes ")
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "link-info",
                  to: '/individu/configuration/groupe/'
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Créez des groupes de personnes ")
                  ])),
                  _: 1
                })),
            (_unref(groupeIndividuStore).listeGroupeIndividu?.length!==0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", _hoisted_4, _toDisplayString(groupeSelectionne.value !==null ? groupeSelectionne.value.libelle: 'Sélectionnez un groupe'), 1),
                  _createElementVNode("ul", _hoisted_5, [
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        class: "dropdown-item",
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectionnerGroupe(null)))
                      }, "Aucun")
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupeIndividuStore).listeGroupeIndividu, (gr) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: gr.id
                      }, [
                        _createElementVNode("a", {
                          class: "dropdown-item",
                          href: "#",
                          onClick: ($event: any) => (selectionnerGroupe(gr))
                        }, [
                          _createTextVNode(_toDisplayString(gr.libelle) + " ", 1),
                          (gr.bloque)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Liste noire"))
                            : _createCommentVNode("", true),
                          (gr.abonnementAutomatique)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Abonnements"))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_6)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listeIndividuPresentation.value, (ind) => {
      return (_openBlock(), _createElementBlock("div", {
        key: ind.id,
        class: "card m-md-1 mb-3 p-3"
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h5", null, [
              _createVNode(_component_router_link, {
                to: "/individu/"+ind.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(ind.pseudonyme), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            (ind.imagePresentation)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: ind.imagePresentation,
                  style: {"width":"50px","height":"50px"},
                  class: "rounded",
                  alt: "Image de profil"
                }, null, 8, _hoisted_11))
              : (_openBlock(), _createElementBlock("i", _hoisted_12))
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", {
              class: "card-text",
              innerHTML: ind.presentation
            }, null, 8, _hoisted_14)
          ]),
          (_unref(individuConnecteStore).individuConnecte && _unref(individuConnecteStore).individuConnecte.id !== ind.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-primary",
                    "data-bs-toggle": "popover",
                    "data-bs-title": "Affectez des groupes à cette personne",
                    "data-ind": JSON.stringify(ind)
                  }, _cache[3] || (_cache[3] = [
                    _createTextVNode(" Groupes "),
                    _createElementVNode("i", {
                      class: "fa fa-plus",
                      "aria-hidden": "true"
                    }, null, -1)
                  ]), 8, _hoisted_17)
                ]),
                (bloque(ind))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Liste noire"))
                  : _createCommentVNode("", true),
                (abonnementAutomatique(ind))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Abonnements"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ], 64))
}
}

})