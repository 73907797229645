import { defineStore } from "pinia";
import { ref } from "vue";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";
import { GroupeIndividu, IndividuPresentation } from "@/classes/individu";


export const useGroupeIndividuStore = defineStore('groupeIndividuStore', () => {
  const listeGroupeIndividu = ref<GroupeIndividu[] | null>(null);
  const erreurRecuperationListe = ref<Erreur | null>(null)
  const rechercherListe = async () => {
    if (listeGroupeIndividu.value == null) {
      try {
        const response = await axiosInstance.get(`/private/groupeindividu/`);
        listeGroupeIndividu.value = response.data;
      } catch (error) {
        erreurRecuperationListe.value = new Erreur("Erreur lors de la récupération de l'individu")
      }
    }
  }
  const creerGroupeIndividu = async (gr: GroupeIndividu) => {
    if (gr.libelle.length != 0 && gr.libelle.length <= 30) {
      try {
        const reponse = await axiosInstance.post('/private/groupeindividu/', gr);
        listeGroupeIndividu.value?.unshift(reponse.data);
      } catch (error: unknown) {
        if (error != null) {
          return new Erreur(error)
        }
      }
    }
    return null;
  }
  const modifierGroupeIndividu = async (gr: GroupeIndividu) => {
    if (gr.libelle.length < 30) {
      try {
        const reponse = await axiosInstance.put('/private/groupeindividu/', gr);
        if (reponse.data === true) {
          const gr2 = listeGroupeIndividu.value?.find((x) => x.id === gr.id)
          if (gr2) gr2.libelle = gr.libelle;
        }
      } catch (error: unknown) {
        if (error != null) {
          return new Erreur(error)
        }
      }
    }
  }
  const supprimerGroupeIndividu = async (gr: GroupeIndividu) => {
    try {
      const reponse = await axiosInstance.delete(`/private/groupeindividu/${gr.id}`);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const index = listeGroupeIndividu.value?.findIndex((x: GroupeIndividu) => x.id === gr.id)
        listeGroupeIndividu.value.splice(index, 1)
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  const ajouterMembre = async (groupeIndividu: GroupeIndividu, ind: IndividuPresentation) => {
    try {
      const reponse = await axiosInstance.put(`/private/groupeindividu/membre-ajout/${ind.id}`,groupeIndividu);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const gr2 = listeGroupeIndividu.value?.find((x) => x.id === groupeIndividu.id)
        console.log(gr2)
        if (gr2) {
          gr2.listeMembre.push(ind);
        }
        console.log(gr2)
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  const supprimerMembre = async (groupeIndividu: GroupeIndividu, ind: IndividuPresentation) => {
    try {
      const reponse = await axiosInstance.put(`/private/groupeindividu/membre-suppression/${ind.id}`,groupeIndividu);
      if (reponse.data === true && listeGroupeIndividu.value) {
        const gr2 = listeGroupeIndividu.value?.find((x) => x.id === groupeIndividu.id)
        if (gr2) {
          const index = gr2.listeMembre.findIndex((x: IndividuPresentation) => x.id===ind.id)
          gr2.listeMembre.splice(index, 1);
        }
      }
    } catch (error: unknown) {
      if (error != null) {
        return new Erreur(error)
      }
    }
  }
  return {
    creerGroupeIndividu,
    rechercherListe,
    modifierGroupeIndividu,
    supprimerGroupeIndividu,
    ajouterMembre,
    supprimerMembre,
    listeGroupeIndividu
  }
})