import { defineStore } from "pinia";
import axiosInstance from "@/utils/axios-config";
import { Erreur } from "@/utils/Erreur";
import { computed, ref } from "vue";
import { GroupeIndividu, IndividuPresentation } from "@/classes/individu";



export const useIndividuPresentationStore = defineStore('individuPresentation', () => {
  const map = ref(new Map<number, IndividuPresentation>());
  const erreurSelection = ref<Erreur | null>(null);
  const erreurModification = ref<Erreur | null>(null);
  const individuSelectionne = ref<IndividuPresentation | null>(null);


  //TODO -> on va chercher ça par page à un moment donné et y mettre des filtres
  async function donneListeIndividuPresentationPublique(){
    try {
      const reponse = await axiosInstance.get(`/public/individu/`);
      return reponse.data as IndividuPresentation[]
    } catch (error) {
      erreurSelection.value = new Erreur("Erreur lors de la récupération de l'individu")
    }
    return null;
  }

  //TODO -> on va chercher ça par page à un moment donné et y mettre des filtres
  async function donneListeIndividuPresentationPrivee(groupe? : GroupeIndividu){
    try {
      let strRequete= ""
      if(groupe !== undefined && groupe!==null) {
        strRequete =`idGroupe=${groupe.id}`
      }
      if(strRequete!="") strRequete="?"+strRequete

      const reponse = await axiosInstance.get(`/private/individu/${strRequete}`);
      return reponse.data as IndividuPresentation[]
    } catch (error) {
      erreurSelection.value = new Erreur("Erreur lors de la récupération de l'individu")
    }
    return null;
  }

  async function rechercherById(idIndividu: number) {
    if (!map.value.has(idIndividu)) {
      try {
        const response = await axiosInstance.get(`/private/individu/${idIndividu}`);
        map.value.set(idIndividu, response.data);
      } catch (error) {
        erreurSelection.value = new Erreur("Erreur lors de la récupération de l'individu")
      }
    }
    return map.value.get(idIndividu) || null;
  }

  async function selectionnerIndividu(idIndividu: number) {
    individuSelectionne.value = await rechercherById(idIndividu)
  }

  async function modifier(pseudonyme: string, textePresentation: string, imagePresentation: string) {
    if (!individuSelectionne.value) {
      erreurModification.value = erreurSelection.value;
      return;
    }
    if (pseudonyme.length == 0 || pseudonyme.length > 30) {
      erreurModification.value = new Erreur("Le pseudonyme ne doit pas dépasser 30 caractères")
      return
    }

    if (textePresentation && textePresentation.length > 2000) {
      erreurModification.value = new Erreur("Le texte de présentation ne doit pas dépasser 500 caractères, mise en forme comprise")
    }
    individuSelectionne.value.imagePresentation = imagePresentation
    individuSelectionne.value.pseudonyme = pseudonyme
    individuSelectionne.value.presentation = textePresentation
    if (individuSelectionne.value && !erreurModification.value) {
      try {
        await axiosInstance.put('/private/individu/', individuSelectionne.value);
        erreurSelection.value = null;
      } catch (error: unknown) {
        if (error != null) {
          erreurModification.value = new Erreur(error)
        }
      }
    }
    return;
  }

  const estErreurSelection = computed(() => {
    return erreurSelection.value !=null
  })


  return {
    selectionnerIndividu,
    donneListeIndividuPresentationPrivee,
    donneListeIndividuPresentationPublique,
    estErreurSelection,
    individuSelectionne,
    erreurModification,
    erreurSelection,
    modifier
  }
});