<script setup lang="ts">

</script>

<template>
  <h1>Politique de Confidentialité</h1>

  <p><strong>Date d'entrée en vigueur :</strong> 24/09/2024</p>

  <p>Cette politique de confidentialité décrit comment la plateforme d'écriture collecte, utilise, et protège vos données personnelles. Nous nous engageons à respecter votre vie privée et à protéger vos données personnelles conformément au Règlement Général sur la Protection des Données (RGPD).</p>

  <h2>1. Données que nous collectons</h2>
  <ul>
    <li><strong>E-mail</strong> : Utilisé pour l'authentification et la vérification de votre compte.</li>
    <li><strong>Date de naissance</strong> : Utilisée pour vérifier que vous avez quinze ans ou plus.</li>
    <li><strong>Pseudonyme</strong> : Permet d’identifier votre compte sans divulguer votre vrai nom.</li>
    <li><strong>Image de profil</strong> (si vous choisissez d'en fournir une) : Utilisée pour personnaliser votre expérience sur notre site.</li>
  </ul>

  <h2>2. Finalités de la collecte des données</h2>
  <ul>
    <li>Vous permettre de créer et gérer votre compte sur notre site.</li>
    <li>Vous authentifier lors de votre connexion.</li>
    <li>Vérifier votre âge pour respecter les exigences légales.</li>
    <li>Personnaliser votre expérience utilisateur.</li>
    <li>Communiquer avec vous au sujet de votre compte ou de nos services.</li>
  </ul>

  <h2>3. Base légale du traitement des données</h2>
  <p>Le traitement de vos données personnelles est fondé sur votre consentement, qui est obtenu lors de votre inscription sur notre site. Vous avez le droit de retirer votre consentement à tout moment.</p>

  <h2>4. Durée de conservation des données</h2>
  <p>Nous conservons vos données personnelles aussi longtemps que votre compte est actif ou nécessaire pour fournir nos services. Si vous souhaitez supprimer votre compte, vous pouvez le faire à tout moment, et nous supprimerons vos données dans les plus brefs délais.</p>

  <h2>5. Sécurité des données</h2>
  <p>Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre la perte, le vol et l'accès non autorisé. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est complètement sécurisée.</p>

  <h2>6. Gestion des cookies</h2>
  <p>Notre site utilise un cookie d'authentification pour améliorer votre expérience utilisateur. Voici les détails concernant son utilisation :</p>
  <ul>
    <li><strong>Cookie d'authentification</strong> : Ce cookie est utilisé pour vous identifier lorsque vous vous connectez à votre compte. Il garantit que vous êtes reconnu et que vous pouvez accéder à votre profil et à nos services en toute sécurité.</li>
    <li><strong>Durée de conservation</strong> : Le cookie d'authentification est conservé pendant la durée de votre session. Si vous vous déconnectez ou fermez votre navigateur, le cookie sera supprimé.</li>
  </ul>
  <p>En utilisant notre site, vous consentez à l'utilisation de ce cookie. Vous pouvez configurer votre navigateur pour qu'il refuse tous les cookies ou pour qu'il vous avertisse lorsque des cookies sont envoyés. Cependant, si vous désactivez les cookies, certaines fonctionnalités de notre site peuvent ne pas fonctionner correctement.</p>

  <h2>7. Vos droits</h2>
  <p>Conformément au RGPD, vous disposez des droits suivants :</p>
  <ul>
    <li><strong>Droit d'accès</strong> : Vous pouvez demander une copie de vos données personnelles que nous détenons.</li>
    <li><strong>Droit de rectification</strong> : Vous pouvez demander la correction de données inexactes ou incomplètes.</li>
    <li><strong>Droit à l'effacement</strong> : Vous pouvez demander la suppression de vos données personnelles.</li>
    <li><strong>Droit à la limitation du traitement</strong> : Vous pouvez demander que nous limitions le traitement de vos données.</li>
    <li><strong>Droit à la portabilité</strong> : Vous avez le droit de recevoir vos données dans un format structuré, couramment utilisé et lisible par machine.</li>
    <li><strong>Droit d'opposition</strong> : Vous pouvez vous opposer au traitement de vos données personnelles pour des raisons légitimes.</li>
  </ul>
  <p>Pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante : damien.polizzzi@gmail.com.</p>

  <h2>8. Modifications de la politique de confidentialité</h2>
  <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous informerons de toute modification en publiant la nouvelle politique sur notre site. Nous vous encourageons à consulter cette page régulièrement pour rester informé de nos pratiques.</p>

  <h2>9. Contact</h2>
  <p>Si vous avez des questions concernant cette politique de confidentialité ou le traitement de vos données personnelles, veuillez nous contacter à :</p>
  <p><strong>E-mail</strong> : damien.polizzi@gmail.com</p>

</template>

<style scoped lang="scss">

</style>