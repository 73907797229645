import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useIndividuConnecteStore } from '@/store/individu-connecte-store';
import ConnexionCompte from "@/pages/ConnexionCompte.vue";
import HomeVide from "@/pages/HomeVide.vue";
import IndividuProfil from "@/pages/IndividuProfil.vue";
import IndividuConfigurationDonneePublique from "@/pages/configuration/IndividuConfigurationDonneePublique.vue";
import IndividuConfigurationDonneePrive from "@/pages/configuration/IndividuConfigurationDonneePrive.vue";
import IndividuConfigurationGroupe from "@/pages/configuration/IndividuConfigurationGroupe.vue";
import FinalisationCreationCompte from "@/pages/FinalisationCreationCompte.vue";
import FinalisationRecuperationCompte from "@/pages/FinalisationRecuperationCompte.vue";
import PolitiqueConfidentialite from "@/pages/PolitiqueConfidentialite.vue";
import RechercheAuteur from "@/pages/recherche/RechercheAuteur.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeVide",
    component: HomeVide,
    async beforeEnter() {
      const storeIndividuConnecte = useIndividuConnecteStore()
      await useIndividuConnecteStore().connecterIndividu()
      if (!storeIndividuConnecte.individuConnecte) {
        return{
          path: '/connexion/'
        }
      }
      return {
        name: "individu-profil",
        params: { id: storeIndividuConnecte.individuConnecte.id }
      }
    }
  },
  {
    path: "/individu/:id",
    name: "individu-profil",
    component: IndividuProfil,
  },
  {
    async beforeEnter() {
      await useIndividuConnecteStore().connecterIndividu()
      const storeIndividuConnecte = useIndividuConnecteStore()
      if (!storeIndividuConnecte.individuConnecte) {
        return {
          path: '/connexion/'
        }
      }
      return true
    },
    path: "/individu/configuration/donnee-publique/",
    name: "individu-configuration-donnee-publique",
    component: IndividuConfigurationDonneePublique,
  },
  {
    async beforeEnter() {
      await useIndividuConnecteStore().connecterIndividu()
      const storeIndividuConnecte = useIndividuConnecteStore()
      if (!storeIndividuConnecte.individuConnecte) {
        return {
          path: '/connexion/'
        }
      }
      return true
    },
    path: "/individu/configuration/donnee-prive/",
    name: "individu-configuration-donnee-prive",
    component: IndividuConfigurationDonneePrive,
  },
  {
    async beforeEnter() {
      await useIndividuConnecteStore().connecterIndividu()
      const storeIndividuConnecte = useIndividuConnecteStore()
      if (!storeIndividuConnecte.individuConnecte) {
        return {
          path: '/connexion/'
        }
      }
      return true
    },
    path: "/individu/configuration/groupe/",
    name: "individu-configuration-groupe",
    component: IndividuConfigurationGroupe,
  },
  {
    path: "/connexion",
    name: "Connexion",
    component: ConnexionCompte
  },
  {
    path: "/creation-compte/:code",
    name: "FinalisationCreationCompte",
    component: FinalisationCreationCompte
  },
  {
    path: "/recuperation-compte/:code",
    name: "FinalisationRecuperationCompte",
    component: FinalisationRecuperationCompte
  },
  {
    path: "/confidentialite/",
    name: "politique-confidentialite",
    component: PolitiqueConfidentialite,

  },
  {
    path: "/recherche-auteur/",
    name: "RechercheAuteur",
    component: RechercheAuteur
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
