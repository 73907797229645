<template>
  <div class="card m-3 ">
    <div class="row">
      <div class="col-sm-4 col-md-3 col-xl-2 m-3">
        <img v-if="storeIndividuPresentation.individuSelectionne?.imagePresentation"
             :src="storeIndividuPresentation.individuSelectionne?.imagePresentation" style="width: 250px;"
             class="img-fluid rounded" alt="Image de profil">
        <i v-else class="fa-solid fa-user fa-3x"
           style="width: 250px; height: 250px; display: flex; justify-content: center; align-items: center;"
           aria-hidden="true"></i>
      </div>
      <div class="col-sm-8 col-md-8 col-xl-8">
        <div class="card-body">
          <h5 class="card-title mb-3">{{ storeIndividuPresentation.individuSelectionne?.pseudonyme }}</h5>
          <span v-if="bloque()" class="badge bg-danger rounded-pill">Liste noire</span>
          <span v-if="abonnementAutomatique()" class="badge bg-success rounded-pill">Abonnements</span>
          <div v-if="storeIndividuConnecte.individuConnecte?.id!=storeIndividuPresentation.individuSelectionne?.id" class="dropdown m-2">
            <button type="button" class="btn btn-sm btn-primary"
                    data-bs-toggle="popover" data-bs-title="Affectez des groupes">
              Groupes <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
          <hr />
          <p class="card-text" v-html="storeIndividuPresentation.individuSelectionne?.presentation"></p>
        </div>
      </div>
    </div>

  </div>
  <ul class="nav nav-tabs" v-if="!bloque()">
    <li class="nav-item">
      <router-link class="nav-link" :to="'/individu/'+id+'/texte/'">Textes</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/individu/'+id+'/lecture/'">Lectures</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/individu/'+id+'/atelier/'">Ateliers</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/individu/'+id+'/discussion/'">Discussions</router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/individu/'+id+'/bibliotheque/'">Bibliothèque</router-link>
    </li>
  </ul>
  <router-view></router-view>
</template>

<style lang="scss">
.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}
</style>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { createApp, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { Popover, Tooltip } from "bootstrap";
import AffectationIndividuGroupe from "@/composant/AffectationIndividuGroupe.vue";

const route = useRoute()
const id = Number(route.params.id);

const storeIndividuConnecte = useIndividuConnecteStore()
const storeIndividuPresentation = useIndividuPresentationStore()
const storeGroupeIndividu = useGroupeIndividuStore()

const isIndividuConnecte = ref(false)
const popovers = ref<Popover[]>([])

const bloque = (): boolean => {
  if (storeGroupeIndividu.listeGroupeIndividu !== null) {
    return storeGroupeIndividu.listeGroupeIndividu
      .filter(groupe => groupe.bloque === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === storeIndividuPresentation.individuSelectionne.id));
  }
}

const abonnementAutomatique = ():boolean =>{
  if (storeGroupeIndividu.listeGroupeIndividu !== null) {
    return storeGroupeIndividu.listeGroupeIndividu
      .filter(groupe => groupe.abonnementAutomatique === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === storeIndividuPresentation.individuSelectionne.id));
  }
}
onMounted(async () => {
  try {
    await storeIndividuPresentation.selectionnerIndividu(id)
    isIndividuConnecte.value = storeIndividuConnecte.individuConnecte?.id === id
    await storeGroupeIndividu.rechercherListe()


    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))


    await nextTick(() => {
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
        const popoverContentEl = document.createElement('div');
        const app = createApp(AffectationIndividuGroupe, { individu: storeIndividuPresentation.individuSelectionne });
        app.mount(popoverContentEl);
        const popover = new Popover(popoverTriggerEl, {
          html: true,
          content: popoverContentEl,
          trigger: "manual"
        });
        // Stocker le popover et le bouton déclencheur
        popovers.value.push(popover);

        let popoverTimeout: number;

        // Fonction pour démarrer ou prolonger le délai
        const startPopoverTimer = () => {
          clearTimeout(popoverTimeout); // Annule tout timer actif
          popoverTimeout = setTimeout(() => popover.hide(), 3000); // Fermeture après 3 secondes
        };

        // Ouvre le popover et démarre le délai lors du clic sur le déclencheur
        popoverTriggerEl.addEventListener('click', () => {
          clearTimeout(popoverTimeout);
          popover.show();
          startPopoverTimer();
        });

        // Prolonge le délai lorsqu'on clique dans le contenu du popover
        popoverContentEl.addEventListener('click', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        popoverContentEl.addEventListener('over', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        // Nettoie le timer si le popover est fermé manuellement
        popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
          clearTimeout(popoverTimeout);
        });
      })
    });
  }
  catch (error){
    console.error(error);
  }
});


</script>