import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "list-group",
  role: "tablist"
}
const _hoisted_2 = {
  key: 0,
  class: "badge bg-danger rounded-pill"
}
const _hoisted_3 = {
  key: 1,
  class: "badge bg-success rounded-pill"
}
const _hoisted_4 = { class: "form-check form-switch" }
const _hoisted_5 = ["checked", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupeIndividuStore.listeGroupeIndividu, (gr) => {
      return (_openBlock(), _createElementBlock("li", {
        key: gr.id,
        class: "list-group-item list-group-item-action d-flex justify-content-between align-items-start"
      }, [
        _createTextVNode(_toDisplayString(gr.libelle) + " ", 1),
        (gr.bloque)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Liste noire"))
          : _createCommentVNode("", true),
        (gr.abonnementAutomatique)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Abonnements"))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("input", {
            class: "form-check-input",
            checked: _ctx.mapGroupeAppartenance.get(gr.id),
            type: "checkbox",
            id: "flexSwitchCheckDefault",
            onClick: _withModifiers(($event: any) => (_ctx.toggleIndividuDansGroupe(gr)), ["prevent"])
          }, null, 8, _hoisted_5)
        ])
      ]))
    }), 128))
  ]))
}