import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "contenant-principal" }
const _hoisted_2 = { class: "contenant" }
const _hoisted_3 = {
  class: "card contenu",
  title: "Connexion"
}
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "form-group col-auto" }
const _hoisted_7 = { class: "form-group col-auto" }
const _hoisted_8 = { class: "password-container" }
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = ["type"]
const _hoisted_11 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_12 = {
  id: "demandeCreationCompteModal",
  class: "modal",
  tabindex: "-1"
}
const _hoisted_13 = { class: "modal-dialog" }
const _hoisted_14 = { class: "modal-content" }
const _hoisted_15 = { class: "modal-body" }
const _hoisted_16 = {
  id: "demandeRecuperationCompteModal",
  class: "modal",
  tabindex: "-1"
}
const _hoisted_17 = { class: "modal-dialog" }
const _hoisted_18 = { class: "modal-content" }
const _hoisted_19 = { class: "modal-body" }

import { ref } from "vue";
import { useRouter } from 'vue-router';
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import DemandeCreationCompteComposant from "@/pages/DemandeCreationCompte.vue";
import DemandeRecuperationCompte from "@/pages/DemandeRecuperationCompte.vue";
import { useGestionCompteStore } from "@/store/gestion-compte-store";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnexionCompte',
  setup(__props) {

const router = useRouter();
const courriel = ref("");
const password = ref("");
const montrePasswordClair = ref(false)


const individuConnectestore = useIndividuConnecteStore()
const gestionCompteStore = useGestionCompteStore()
const isCreationModalVisible = ref(false);
const isRecuperationModalVisible = ref(false);

const changeVisibilitePassword = () =>
  montrePasswordClair.value = !montrePasswordClair.value;
const showCreationModal = () => {
  isCreationModalVisible.value = true;
};
const showRecuperationModal = () => {
  isRecuperationModalVisible.value = true;
};
const connexion = async () => {
  await gestionCompteStore.connexion(courriel.value, password.value);
  await individuConnectestore.connecterIndividu()
  if (individuConnectestore.individuConnecte != null) {
    await router.push({ path: '/' });
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("div", {
      class: "alert alert-danger text-center placard",
      role: "alert"
    }, "!!! Site en cours de construction !!! ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "card-title" }, "Connexion", -1)),
          _createElementVNode("form", {
            onSubmit: _withModifiers(connexion, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("label", { for: "courriel" }, "Courriel", -1)),
                _withDirectives(_createElementVNode("input", {
                  id: "courriel",
                  type: "email",
                  class: "form-control",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((courriel).value = $event)),
                  placeholder: "mon.courriel@example.com"
                }, null, 512), [
                  [_vModelText, courriel.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "Mot de passe", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      id: "password",
                      type: montrePasswordClair.value ? 'text' : 'password',
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                      class: "form-control",
                      placeholder: "Mot de passe"
                    }, null, 8, _hoisted_10), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", { class: "input-group-append" }, [
                      _createElementVNode("button", {
                        class: "btn btn-outline-secondary",
                        type: "button",
                        onClick: changeVisibilitePassword
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "fas fa-eye" }, null, -1)
                      ]))
                    ])
                  ])
                ])
              ]),
              (_unref(gestionCompteStore).estErreurConnexion)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(gestionCompteStore)?.erreurConnexion?.message), 1))
                : _createCommentVNode("", true),
              _cache[5] || (_cache[5] = _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary"
              }, "Connexion", -1))
            ])
          ], 32),
          _cache[9] || (_cache[9] = _createElementVNode("p", null, [
            _createElementVNode("button", {
              class: "btn btn-link",
              "data-bs-toggle": "modal",
              "data-bs-target": "#demandeCreationCompteModal"
            }, "Créer un compte ")
          ], -1)),
          _cache[10] || (_cache[10] = _createElementVNode("p", null, [
            _createElementVNode("button", {
              class: "btn btn-link",
              "data-bs-toggle": "modal",
              "data-bs-target": "#demandeRecuperationCompteModal"
            }, "J'ai oublié mon mot de passe")
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "modal-header" }, [
                  _createElementVNode("h5", { class: "modal-title" }, "Création de compte"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn-close",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close"
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(DemandeCreationCompteComposant)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-header" }, [
                  _createElementVNode("h5", { class: "modal-title" }, "Récupération de compte"),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn-close",
                    "data-bs-dismiss": "modal",
                    "aria-label": "Close"
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(DemandeRecuperationCompte)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})