import axiosInstance from '../utils/axios-config';
import { defineStore } from "pinia";
import { ref } from "vue";
import { Erreur } from "@/utils/Erreur";
import { Individu } from "@/classes/individu";




export const useIndividuConnecteStore = defineStore('individuConnecte', () => {
  const individuConnecte = ref<Individu|null>(null)
  const erreurConnexion = ref<Erreur|null>(null)

  async function connecterIndividu() {
    if (individuConnecte.value == null) {
      try {
        const response = await axiosInstance.get('/private/individu/me/')
        individuConnecte.value = response.data
        erreurConnexion.value =null

      } catch (error: unknown) {
        erreurConnexion.value = new Erreur(error)
      }
    }
  }

  async function hasRole(role: string){
    return individuConnecte.value?.lstRole.includes(role)
  }

  async function deconnexion() {
    await axiosInstance.get('/private/auth/deconnexion/')
    individuConnecte.value = null
    return individuConnecte
  }

  return { individuConnecte, erreurConnexion, connecterIndividu, deconnexion, hasRole };
});

