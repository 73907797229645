import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const hello="hello"

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeVide',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p"))
}
}

})