<template>
  <div class="row">
    <div class="col-s-12 col-md-10 col-xl-8 mx-auto">
      <div class="card mb-3 card-body">
        <h2 class="card-title">Gestion des groupes de personnes</h2>
        <div class="card-text">Les groupes vous permettront de paramétrer finement les droits de lecture
          sur vos oeuvres
          en autorisant l'accès à des oeuvres privées ou en interdisant l'accès à des oeuvres visibles par la
          communeauté.
        </div>
      </div>
      <a class="btn-secondary" data-bs-toggle="collapse" href="#collapseElement" role="button" aria-expanded="false"
         aria-controls="collapseExample">
        Créer un groupe
      </a>
      <div id="collapseElement" class="collapse">
        <form @submit.prevent="creerGroupe" class="needs-validation ">
          <h3>Création de groupes</h3>
          <div class="form-group">
            <label class="form-label">Libellé</label>
            <input type="text" class="form-control" placeholder="Mes amis" v-model="libelle"
                   :class="isLibelleValid? 'is-valid':'is-invalid'"
                   @input="evaluationValiditeLibelle">
            <div class="invalid-feedback">
              Le libellé ne doit pas dépasser 30 caractères
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListeCreation"
                   value="">
            <label class="form-check-label">
              Juste une liste pour retrouver facilement les gens ou leur donner des droits sur mes œuvres
            </label>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListeCreation"
                     value="abonnementAutomatique">
              <label class="form-check-label">
                Abonnements : Je veux m'abonner automatiquement aux œvres des membres de la liste
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListeCreation"
                     value="bloque">
              <label class="form-check-label">
                Liste noire : je ne veux plus avoir à faire avec ces personnes
              </label>
            </div>
          </div>
          <button type="submit" class="btn btn-primary" :disabled="!isLibelleValid || !isCreationEnabled">Créer
          </button>
        </form>
      </div>
      <hr />
      <h3>Liste des groupes</h3>
      <p>
        <router-link class="link-info" :to="'/recherche-auteur/'">Gérer le contenu de vos listes ici</router-link>
      </p>
      <div class="row">
        <ul class="list-group col-sm-12 col-md-6" role="tablist">
          <li v-for="gr in groupeIndividuStore.listeGroupeIndividu" :key="gr.id"
              class="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
              :class="groupeSelectionne?.id===gr.id ? 'active':''"
              data-bs-toggle="list" @click="selectionner(gr)">
            {{ gr.libelle }}
            <span>
                <span v-if="gr.bloque" class="badge bg-danger rounded-pill">Liste noire</span>
                <span v-if="gr.abonnementAutomatique" class="badge bg-success rounded-pill ms-1">Abonnements</span>
                <span class="badge text-bg-primary rounded-pill ms-1">{{ gr.listeMembre.length }}</span>
                <button class="btn btn-link bg-danger ms-1" @click="ouvrirModalSuppression(gr)">
                  <i class="fa-solid fa-trash-can"></i>
                </button>
              </span>
          </li>
        </ul>
        <div v-if="groupeSelectionne" class="col-sm-12 col-md-6">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Mes amis" v-model="groupeSelectionne.libelle"
                   :class="isLibelleGroupeSelectionneValid? 'is-valid':'is-invalid'"
                   @input="evaluationValiditeLibelleGroupeSelectionne">
            <button class="btn btn-outline-secondary" type="button" :disabled="!isLibelleGroupeSelectionneValid"
                    @click.prevent="modifierGroupe">Modifier
            </button>
            <div class="invalid-feedback">
              Le libellé ne doit pas dépasser 30 caractères
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListe"
                   value="abonnementAutomatique" @change="modifierGroupe">
            <label class="form-check-label">
              Abonnements : Je veux m'abonner automatiquement aux œvres des membres de la liste
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListe"
                   value="bloque" @change="modifierGroupe">
            <label class="form-check-label">
              Liste noire : je ne veux plus avoir à faire avec ces personnes
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" v-model="choixTypeListe" value=""
                   @change="modifierGroupe">
            <label class="form-check-label">
              Juste une liste pour retrouver facilement les gens ou leur donner des droits sur mes œuvres
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="suppressionModal" tabindex="-1" aria-labelledby="suppressionModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="suppressionModalLabel">Confirmer la suppression</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Êtes-vous sûr de vouloir supprimer le groupe "{{ groupeASupprimer?.libelle }}" ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
          <button type="button" class="btn btn-danger" @click="supprimerGroupe">Confirmer</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">

import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { onMounted, ref } from "vue";
import { GroupeIndividu } from "@/classes/individu";
import { Modal } from "bootstrap";

const groupeIndividuStore = useGroupeIndividuStore()
const libelle = ref("");
const isCreationEnabled = ref(true);
const isLibelleValid = ref(false);
const isLibelleGroupeSelectionneValid = ref(false);
const groupeSelectionne = ref<GroupeIndividu | null>(null)
const groupeASupprimer = ref<GroupeIndividu | null>(null)
const groupeACreer = ref<GroupeIndividu | null>(null)

const choixTypeListe = ref("");
const choixTypeListeCreation = ref("");

const evaluationValiditeLibelleGroupeSelectionne = () => {
  if (groupeSelectionne.value) {
    isLibelleGroupeSelectionneValid.value = groupeSelectionne.value.libelle.length !== 0 && groupeSelectionne.value.libelle.length <= 30;
  }else isLibelleGroupeSelectionneValid.value = false;
}
const evaluationValiditeLibelle = () => {
  isLibelleValid.value = libelle.value.length !== 0 && libelle.value.length <= 30;
}
const creerGroupe = async () => {

  isCreationEnabled.value = false
  if (isLibelleValid.value === true) {
    let bloque = false
    let abonnementAutomatique = false
    if (choixTypeListeCreation.value === "bloque") {
      bloque = true
    } else if (choixTypeListeCreation.value === "abonnementAutomatique") {
      abonnementAutomatique = true
    }
    groupeACreer.value = new GroupeIndividu(null, libelle.value, bloque, abonnementAutomatique, [])
    await groupeIndividuStore.creerGroupeIndividu(groupeACreer.value);
    if (groupeIndividuStore.listeGroupeIndividu && groupeIndividuStore.listeGroupeIndividu.length > 0) {
      selectionner(groupeIndividuStore.listeGroupeIndividu[0])
    }
    libelle.value = ""
  }
  isCreationEnabled.value = true

}
const modifierGroupe = () => {
  if (groupeSelectionne.value != null) {
    groupeSelectionne.value.bloque = false
    groupeSelectionne.value.abonnementAutomatique = false
    if (choixTypeListeCreation.value === "bloque") {
      groupeSelectionne.value.bloque = true
    } else if (choixTypeListeCreation.value === "abonnementAutomatique") {
      groupeSelectionne.value.abonnementAutomatique = true
    }
    groupeIndividuStore.modifierGroupeIndividu(groupeSelectionne.value)
  }
}
const ouvrirModalSuppression = (groupe: GroupeIndividu) => {
  groupeASupprimer.value = groupe
  const suppressionElement = document.getElementById('suppressionModal')
  if (suppressionElement) {
    const suppressionModal = new Modal(suppressionElement)
    suppressionModal.show()
  }
}
const supprimerGroupe = async () => {
  const suppressionElement = document.getElementById('suppressionModal')
  if (suppressionElement && groupeASupprimer.value != null) {
    const suppressionModal = Modal.getInstance(suppressionElement)
    await groupeIndividuStore.supprimerGroupeIndividu(groupeASupprimer.value)
    groupeASupprimer.value = null;
    if(suppressionModal) suppressionModal.hide();
    if (groupeIndividuStore.listeGroupeIndividu && groupeIndividuStore.listeGroupeIndividu.length > 0) {
      selectionner(groupeIndividuStore.listeGroupeIndividu[0])
    }
  }
}
const selectionner = (gr: GroupeIndividu) => {
  groupeSelectionne.value = gr;
  if (groupeSelectionne.value.bloque) {
    choixTypeListe.value = "bloque";
  } else if (groupeSelectionne.value.abonnementAutomatique) {
    choixTypeListe.value = "abonnementAutomatique";
  } else {
    choixTypeListe.value = "";
  }
  evaluationValiditeLibelleGroupeSelectionne()
}

onMounted(async () => {
  await groupeIndividuStore.rechercherListe()
});
</script>


<style scoped lang="scss">

</style>