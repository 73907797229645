<template>
  <nav class="nav navbar bg-primary w-100 ">
    <div class="nav-link text-white" data-bs-toggle="offcanvas" data-bs-target="#bigmenu" aria-controls="bigmenu"
         aria-labelledby="bigmenu">
      <a class="navbar-brand text-white" href="#">ChrysaLibris</a>
      <i class="fa-solid fa-chevron-down"></i>
    </div>
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
      <li class="nav-item" v-if="storeIndividuConnecte.individuConnecte?.id !=null"
          @click.prevent="deconnexion">
        <div class="nav-link text-white">
          <i class='fa-solid fa-plug-circle-xmark fa-2'></i>
        </div>
      </li>
      <li class="nav-item" v-else>
        <div class="nav-link">
          <a class="navbar-brand text-white" href="/site/connexion/">
            <i class='fa-solid fa-plug fa-2'></i>
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="bigmenu" aria-labelledby="bigmenu">
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">menu</h4>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Fermer"></button>
    </div>
    <div class="offcanvas-body">
      <div v-if="storeIndividuConnecte.individuConnecte?.id !=null" >
        <h5 data-bs-dismiss="offcanvas">
          <router-link class="btn btn-link" type="button" to="/">
            <i class="fa fa-home" aria-hidden="true"/> Chez moi
          </router-link>
        </h5>
        <ul>
          <li>Mes livres (non implémenté)</li>
          <li>Mes textes monopage (non implémenté)</li>
          <li>Ateliers d'écritures que j'ai créé (non implémenté)</li>
          <li>Discussions que j'ai créées (non implémenté)</li>
          <li>Mes statistiques (non implémenté)</li>
        </ul>
        <hr/>
      </div>
      <div v-if="storeIndividuConnecte.individuConnecte?.id !=null">
        <h5>
          <i class="fa-solid fa-user"></i> Mon profil
        </h5>
        <ul>
          <li data-bs-dismiss="offcanvas">
            <router-link :to="'/individu/configuration/donnee-publique/'">
              Données publiques
            </router-link>
          </li>
          <li data-bs-dismiss="offcanvas">
            <router-link :to="'/individu/configuration/donnee-prive/'">
              Données privées
            </router-link>
          </li>
          <li data-bs-dismiss="offcanvas">
            <router-link :to="'/individu/configuration/groupe/'">
              Gestion des groupes
            </router-link>
          </li>
        </ul>
        <hr/>
      </div>
      <hr/>
      <div>
        <h5>
          <i class="fa-solid fa-users"></i> Explorer d'autres horizons
        </h5>
        <ul>
          <li data-bs-dismiss="offcanvas">
            <router-link to="/recherche-auteur/">Auteurs</router-link>
          </li>
          <li>Livres (non implémenté)</li>
          <li>Textes (non implémenté)</li>
          <li>Ateliers d'écriture (non implémenté)</li>
          <li>Discussions (non implémenté)</li>
        </ul>

        <hr/>
      </div>
      <div>
        <h5>
          <i class="fa-solid fa-book"></i> Écrire/Gérer mes créations
        </h5>
        <ul>
          <li>Livres (non implémenté)</li>
          <li>Textes (non implémenté)</li>
          <li>Ateliers d'écriture (non implémenté)</li>
          <li>Discussions (non implémenté)</li>
        </ul>

        <hr/>
      </div>
      <div>
        <h5>
          <i class="fa-solid fa-info-circle"></i> Explorer d'autres horizons
        </h5>
        <ul>
          <li>Tutos (non implémenté)</li>
          <li data-bs-dismiss="offcanvas">
            <router-link to="/confidentialite/">Confidentialité</router-link>
          </li>
        </ul>

        <hr/>
      </div>
    </div>
  </div>
  <div class="contenu-app">
  <router-view/>
  </div>
</template>


<script setup lang="ts">
import {onMounted} from "vue";
import {useIndividuConnecteStore} from "@/store/individu-connecte-store";
import router from "@/router";
import {getActivePinia, Store} from "pinia";
import { useRoute} from "vue-router";

const storeIndividuConnecte = useIndividuConnecteStore()

const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({path: '/connexion'});
    const pinia = getActivePinia();

    if (pinia && (pinia as any)._s) {
      // Nous typons _s comme une Map<string, Store>
      const stores = (pinia as any)._s as Map<string, Store>;

      stores.forEach((store) => {
        if (typeof store.$reset === 'function') {
          store.$reset();
        }
      });
    }
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

onMounted(() => {
  recupereInfoConnexion()
})
</script>


<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary-color: $primary;
$primary-active-color: darken($primary, 10%);
$primary-hover-color: lighten($primary, 5%);


.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.contenu-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 5em;
  z-index: 1000;
}


.ql-font-monospace {
  font-family: var(--bs-font-monospace);
}

.ql-size-small {
  font-size: calc(var(--bs-body-font-size) * 0.75);
}

.ql-size-large {
  font-size: calc(var(--bs-body-font-size) * 1.5);
}

.ql-size-huge {
  font-size: calc(var(--bs-body-font-size) * 2);
}

.ql-editor * {
  font-size: var(--bs-body-font-size);
}

.ql-font-serif {
  font-family: 'Garamond' !important;
}

.ql-font-gothique {
  font-family: 'Mirage Gothic' !important;
}
</style>
