import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelRadio as _vModelRadio, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-s-12 col-md-10 col-xl-8 mx-auto" }
const _hoisted_3 = {
  id: "collapseElement",
  class: "collapse"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-check" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-check" }
const _hoisted_8 = { class: "form-check" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  class: "list-group col-sm-12 col-md-6",
  role: "tablist"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "badge bg-danger rounded-pill"
}
const _hoisted_14 = {
  key: 1,
  class: "badge bg-success rounded-pill ms-1"
}
const _hoisted_15 = { class: "badge text-bg-primary rounded-pill ms-1" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 0,
  class: "col-sm-12 col-md-6"
}
const _hoisted_18 = { class: "input-group mb-3" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "form-check" }
const _hoisted_21 = { class: "form-check" }
const _hoisted_22 = { class: "form-check" }
const _hoisted_23 = {
  class: "modal fade",
  id: "suppressionModal",
  tabindex: "-1",
  "aria-labelledby": "suppressionModalLabel",
  "aria-hidden": "true"
}
const _hoisted_24 = { class: "modal-dialog" }
const _hoisted_25 = { class: "modal-content" }
const _hoisted_26 = { class: "modal-body" }

import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { onMounted, ref } from "vue";
import { GroupeIndividu } from "@/classes/individu";
import { Modal } from "bootstrap";


export default /*@__PURE__*/_defineComponent({
  __name: 'IndividuConfigurationGroupe',
  setup(__props) {


const groupeIndividuStore = useGroupeIndividuStore()
const libelle = ref("");
const isCreationEnabled = ref(true);
const isLibelleValid = ref(false);
const isLibelleGroupeSelectionneValid = ref(false);
const groupeSelectionne = ref<GroupeIndividu | null>(null)
const groupeASupprimer = ref<GroupeIndividu | null>(null)
const groupeACreer = ref<GroupeIndividu | null>(null)

const choixTypeListe = ref("");
const choixTypeListeCreation = ref("");

const evaluationValiditeLibelleGroupeSelectionne = () => {
  if (groupeSelectionne.value) {
    isLibelleGroupeSelectionneValid.value = groupeSelectionne.value.libelle.length !== 0 && groupeSelectionne.value.libelle.length <= 30;
  }else isLibelleGroupeSelectionneValid.value = false;
}
const evaluationValiditeLibelle = () => {
  isLibelleValid.value = libelle.value.length !== 0 && libelle.value.length <= 30;
}
const creerGroupe = async () => {

  isCreationEnabled.value = false
  if (isLibelleValid.value === true) {
    let bloque = false
    let abonnementAutomatique = false
    if (choixTypeListeCreation.value === "bloque") {
      bloque = true
    } else if (choixTypeListeCreation.value === "abonnementAutomatique") {
      abonnementAutomatique = true
    }
    groupeACreer.value = new GroupeIndividu(null, libelle.value, bloque, abonnementAutomatique, [])
    await groupeIndividuStore.creerGroupeIndividu(groupeACreer.value);
    if (groupeIndividuStore.listeGroupeIndividu && groupeIndividuStore.listeGroupeIndividu.length > 0) {
      selectionner(groupeIndividuStore.listeGroupeIndividu[0])
    }
    libelle.value = ""
  }
  isCreationEnabled.value = true

}
const modifierGroupe = () => {
  if (groupeSelectionne.value != null) {
    groupeSelectionne.value.bloque = false
    groupeSelectionne.value.abonnementAutomatique = false
    if (choixTypeListeCreation.value === "bloque") {
      groupeSelectionne.value.bloque = true
    } else if (choixTypeListeCreation.value === "abonnementAutomatique") {
      groupeSelectionne.value.abonnementAutomatique = true
    }
    groupeIndividuStore.modifierGroupeIndividu(groupeSelectionne.value)
  }
}
const ouvrirModalSuppression = (groupe: GroupeIndividu) => {
  groupeASupprimer.value = groupe
  const suppressionElement = document.getElementById('suppressionModal')
  if (suppressionElement) {
    const suppressionModal = new Modal(suppressionElement)
    suppressionModal.show()
  }
}
const supprimerGroupe = async () => {
  const suppressionElement = document.getElementById('suppressionModal')
  if (suppressionElement && groupeASupprimer.value != null) {
    const suppressionModal = Modal.getInstance(suppressionElement)
    await groupeIndividuStore.supprimerGroupeIndividu(groupeASupprimer.value)
    groupeASupprimer.value = null;
    if(suppressionModal) suppressionModal.hide();
    if (groupeIndividuStore.listeGroupeIndividu && groupeIndividuStore.listeGroupeIndividu.length > 0) {
      selectionner(groupeIndividuStore.listeGroupeIndividu[0])
    }
  }
}
const selectionner = (gr: GroupeIndividu) => {
  groupeSelectionne.value = gr;
  if (groupeSelectionne.value.bloque) {
    choixTypeListe.value = "bloque";
  } else if (groupeSelectionne.value.abonnementAutomatique) {
    choixTypeListe.value = "abonnementAutomatique";
  } else {
    choixTypeListe.value = "";
  }
  evaluationValiditeLibelleGroupeSelectionne()
}

onMounted(async () => {
  await groupeIndividuStore.rechercherListe()
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "card mb-3 card-body" }, [
          _createElementVNode("h2", { class: "card-title" }, "Gestion des groupes de personnes"),
          _createElementVNode("div", { class: "card-text" }, "Les groupes vous permettront de paramétrer finement les droits de lecture sur vos oeuvres en autorisant l'accès à des oeuvres privées ou en interdisant l'accès à des oeuvres visibles par la communeauté. ")
        ], -1)),
        _cache[21] || (_cache[21] = _createElementVNode("a", {
          class: "btn-secondary",
          "data-bs-toggle": "collapse",
          href: "#collapseElement",
          role: "button",
          "aria-expanded": "false",
          "aria-controls": "collapseExample"
        }, " Créer un groupe ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(creerGroupe, ["prevent"]),
            class: "needs-validation"
          }, [
            _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Création de groupes", -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Libellé", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: _normalizeClass(["form-control", isLibelleValid.value? 'is-valid':'is-invalid']),
                placeholder: "Mes amis",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((libelle).value = $event)),
                onInput: evaluationValiditeLibelle
              }, null, 34), [
                [_vModelText, libelle.value]
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "invalid-feedback" }, " Le libellé ne doit pas dépasser 30 caractères ", -1))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                class: "form-check-input",
                type: "radio",
                name: "flexRadioDefault",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((choixTypeListeCreation).value = $event)),
                value: ""
              }, null, 512), [
                [_vModelRadio, choixTypeListeCreation.value]
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-check-label" }, " Juste une liste pour retrouver facilement les gens ou leur donner des droits sur mes œuvres ", -1))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((choixTypeListeCreation).value = $event)),
                  value: "abonnementAutomatique"
                }, null, 512), [
                  [_vModelRadio, choixTypeListeCreation.value]
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-check-label" }, " Abonnements : Je veux m'abonner automatiquement aux œvres des membres de la liste ", -1))
              ]),
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((choixTypeListeCreation).value = $event)),
                  value: "bloque"
                }, null, 512), [
                  [_vModelRadio, choixTypeListeCreation.value]
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-check-label" }, " Liste noire : je ne veux plus avoir à faire avec ces personnes ", -1))
              ])
            ]),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary",
              disabled: !isLibelleValid.value || !isCreationEnabled.value
            }, "Créer ", 8, _hoisted_9)
          ], 32)
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("hr", null, null, -1)),
        _cache[23] || (_cache[23] = _createElementVNode("h3", null, "Liste des groupes", -1)),
        _createElementVNode("p", null, [
          _createVNode(_component_router_link, {
            class: "link-info",
            to: '/recherche-auteur/'
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("Gérer le contenu de vos listes ici")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("ul", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupeIndividuStore).listeGroupeIndividu, (gr) => {
              return (_openBlock(), _createElementBlock("li", {
                key: gr.id,
                class: _normalizeClass(["list-group-item list-group-item-action d-flex justify-content-between align-items-start", groupeSelectionne.value?.id===gr.id ? 'active':'']),
                "data-bs-toggle": "list",
                onClick: ($event: any) => (selectionner(gr))
              }, [
                _createTextVNode(_toDisplayString(gr.libelle) + " ", 1),
                _createElementVNode("span", null, [
                  (gr.bloque)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Liste noire"))
                    : _createCommentVNode("", true),
                  (gr.abonnementAutomatique)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Abonnements"))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(gr.listeMembre.length), 1),
                  _createElementVNode("button", {
                    class: "btn btn-link bg-danger ms-1",
                    onClick: ($event: any) => (ouvrirModalSuppression(gr))
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("i", { class: "fa-solid fa-trash-can" }, null, -1)
                  ]), 8, _hoisted_16)
                ])
              ], 10, _hoisted_12))
            }), 128))
          ]),
          (groupeSelectionne.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: _normalizeClass(["form-control", isLibelleGroupeSelectionneValid.value? 'is-valid':'is-invalid']),
                    placeholder: "Mes amis",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((groupeSelectionne.value.libelle) = $event)),
                    onInput: evaluationValiditeLibelleGroupeSelectionne
                  }, null, 34), [
                    [_vModelText, groupeSelectionne.value.libelle]
                  ]),
                  _createElementVNode("button", {
                    class: "btn btn-outline-secondary",
                    type: "button",
                    disabled: !isLibelleGroupeSelectionneValid.value,
                    onClick: _withModifiers(modifierGroupe, ["prevent"])
                  }, "Modifier ", 8, _hoisted_19),
                  _cache[16] || (_cache[16] = _createElementVNode("div", { class: "invalid-feedback" }, " Le libellé ne doit pas dépasser 30 caractères ", -1))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    type: "radio",
                    name: "flexRadioDefault",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((choixTypeListe).value = $event)),
                    value: "abonnementAutomatique",
                    onChange: modifierGroupe
                  }, null, 544), [
                    [_vModelRadio, choixTypeListe.value]
                  ]),
                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-check-label" }, " Abonnements : Je veux m'abonner automatiquement aux œvres des membres de la liste ", -1))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    type: "radio",
                    name: "flexRadioDefault",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((choixTypeListe).value = $event)),
                    value: "bloque",
                    onChange: modifierGroupe
                  }, null, 544), [
                    [_vModelRadio, choixTypeListe.value]
                  ]),
                  _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-check-label" }, " Liste noire : je ne veux plus avoir à faire avec ces personnes ", -1))
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    type: "radio",
                    name: "flexRadioDefault",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((choixTypeListe).value = $event)),
                    value: "",
                    onChange: modifierGroupe
                  }, null, 544), [
                    [_vModelRadio, choixTypeListe.value]
                  ]),
                  _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-check-label" }, " Juste une liste pour retrouver facilement les gens ou leur donner des droits sur mes œuvres ", -1))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title",
              id: "suppressionModalLabel"
            }, "Confirmer la suppression"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_26, " Êtes-vous sûr de vouloir supprimer le groupe \"" + _toDisplayString(groupeASupprimer.value?.libelle) + "\" ? ", 1),
          _createElementVNode("div", { class: "modal-footer" }, [
            _cache[24] || (_cache[24] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, "Annuler", -1)),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger",
              onClick: supprimerGroupe
            }, "Confirmer")
          ])
        ])
      ])
    ])
  ], 64))
}
}

})