import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "nav navbar bg-primary w-100" }
const _hoisted_2 = { class: "navbar-nav ms-auto mb-2 mb-lg-0" }
const _hoisted_3 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_4 = {
  class: "offcanvas offcanvas-start",
  tabindex: "-1",
  id: "bigmenu",
  "aria-labelledby": "bigmenu"
}
const _hoisted_5 = { class: "offcanvas-body" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_10 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_11 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_12 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_13 = { "data-bs-dismiss": "offcanvas" }
const _hoisted_14 = { class: "contenu-app" }

import {onMounted} from "vue";
import {useIndividuConnecteStore} from "@/store/individu-connecte-store";
import router from "@/router";
import {getActivePinia, Store} from "pinia";
import { useRoute} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const storeIndividuConnecte = useIndividuConnecteStore()

const deconnexion = async () => {
  try {
    await storeIndividuConnecte.deconnexion();
    await router.push({path: '/connexion'});
    const pinia = getActivePinia();

    if (pinia && (pinia as any)._s) {
      // Nous typons _s comme une Map<string, Store>
      const stores = (pinia as any)._s as Map<string, Store>;

      stores.forEach((store) => {
        if (typeof store.$reset === 'function') {
          store.$reset();
        }
      });
    }
  } catch (error: unknown) {
    console.log("impossible de se déconnecter");
  }
}

const recupereInfoConnexion = async () => {
  await storeIndividuConnecte.connecterIndividu();
};

onMounted(() => {
  recupereInfoConnexion()
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "nav-link text-white",
        "data-bs-toggle": "offcanvas",
        "data-bs-target": "#bigmenu",
        "aria-controls": "bigmenu",
        "aria-labelledby": "bigmenu"
      }, [
        _createElementVNode("a", {
          class: "navbar-brand text-white",
          href: "#"
        }, "ChrysaLibris"),
        _createElementVNode("i", { class: "fa-solid fa-chevron-down" })
      ], -1)),
      _createElementVNode("ul", _hoisted_2, [
        (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: "nav-item",
              onClick: _withModifiers(deconnexion, ["prevent"])
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "nav-link text-white" }, [
                _createElementVNode("i", { class: "fa-solid fa-plug-circle-xmark fa-2" })
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("li", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "nav-link" }, [
                _createElementVNode("a", {
                  class: "navbar-brand text-white",
                  href: "/site/connexion/"
                }, [
                  _createElementVNode("i", { class: "fa-solid fa-plug fa-2" })
                ])
              ], -1)
            ])))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "offcanvas-header" }, [
        _createElementVNode("h4", { class: "offcanvas-title" }, "menu"),
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Fermer"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("h5", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "btn btn-link",
                  type: "button",
                  to: "/"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("i", {
                      class: "fa fa-home",
                      "aria-hidden": "true"
                    }, null, -1),
                    _createTextVNode(" Chez moi ")
                  ])),
                  _: 1
                })
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("ul", null, [
                _createElementVNode("li", null, "Mes livres (non implémenté)"),
                _createElementVNode("li", null, "Mes textes monopage (non implémenté)"),
                _createElementVNode("li", null, "Ateliers d'écritures que j'ai créé (non implémenté)"),
                _createElementVNode("li", null, "Discussions que j'ai créées (non implémenté)"),
                _createElementVNode("li", null, "Mes statistiques (non implémenté)")
              ], -1)),
              _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1))
            ]))
          : _createCommentVNode("", true),
        (_unref(storeIndividuConnecte).individuConnecte?.id !=null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[9] || (_cache[9] = _createElementVNode("h5", null, [
                _createElementVNode("i", { class: "fa-solid fa-user" }),
                _createTextVNode(" Mon profil ")
              ], -1)),
              _createElementVNode("ul", null, [
                _createElementVNode("li", _hoisted_9, [
                  _createVNode(_component_router_link, { to: '/individu/configuration/donnee-publique/' }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Données publiques ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_10, [
                  _createVNode(_component_router_link, { to: '/individu/configuration/donnee-prive/' }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Données privées ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_11, [
                  _createVNode(_component_router_link, { to: '/individu/configuration/groupe/' }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Gestion des groupes ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1))
            ]))
          : _createCommentVNode("", true),
        _cache[22] || (_cache[22] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", null, [
          _cache[16] || (_cache[16] = _createElementVNode("h5", null, [
            _createElementVNode("i", { class: "fa-solid fa-users" }),
            _createTextVNode(" Explorer d'autres horizons ")
          ], -1)),
          _createElementVNode("ul", null, [
            _createElementVNode("li", _hoisted_12, [
              _createVNode(_component_router_link, { to: "/recherche-auteur/" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Auteurs")
                ])),
                _: 1
              })
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("li", null, "Livres (non implémenté)", -1)),
            _cache[13] || (_cache[13] = _createElementVNode("li", null, "Textes (non implémenté)", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("li", null, "Ateliers d'écriture (non implémenté)", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("li", null, "Discussions (non implémenté)", -1))
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1))
        ]),
        _cache[23] || (_cache[23] = _createElementVNode("div", null, [
          _createElementVNode("h5", null, [
            _createElementVNode("i", { class: "fa-solid fa-book" }),
            _createTextVNode(" Écrire/Gérer mes créations ")
          ]),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, "Livres (non implémenté)"),
            _createElementVNode("li", null, "Textes (non implémenté)"),
            _createElementVNode("li", null, "Ateliers d'écriture (non implémenté)"),
            _createElementVNode("li", null, "Discussions (non implémenté)")
          ]),
          _createElementVNode("hr")
        ], -1)),
        _createElementVNode("div", null, [
          _cache[20] || (_cache[20] = _createElementVNode("h5", null, [
            _createElementVNode("i", { class: "fa-solid fa-info-circle" }),
            _createTextVNode(" Explorer d'autres horizons ")
          ], -1)),
          _createElementVNode("ul", null, [
            _cache[19] || (_cache[19] = _createElementVNode("li", null, "Tutos (non implémenté)", -1)),
            _createElementVNode("li", _hoisted_13, [
              _createVNode(_component_router_link, { to: "/confidentialite/" }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode("Confidentialité")
                ])),
                _: 1
              })
            ])
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("hr", null, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}
}

})