<template>
  <div class="row">
    <div class="col-3" v-if="individuConnecteStore.individuConnecte">
      <router-link v-if="groupeIndividuStore.listeGroupeIndividu?.length===0" class="link-info"
                   :to="'/individu/configuration/groupe/'" data-bs-toggle="tooltip"
                   data-bs-title="Les groupes vous permettrons de sélectionner des listes d'auteurs,
                  de gérer les droits que vous souhaitez leur affecter sur vos œuvre ou s'abonner">

        Créez des groupes de personnes
      </router-link>
      <router-link v-else class="link-info" :to="'/individu/configuration/groupe/'">
        Créez des groupes de personnes
      </router-link>
      <div v-if="groupeIndividuStore.listeGroupeIndividu?.length!==0" class="dropdown">
        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ groupeSelectionne !==null ? groupeSelectionne.libelle: 'Sélectionnez un groupe'}}
        </a>

        <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#" @click="selectionnerGroupe(null)">Aucun</a></li>
          <li v-for="gr in groupeIndividuStore.listeGroupeIndividu" :key="gr.id">
            <a  class="dropdown-item" href="#" @click="selectionnerGroupe(gr)">
            {{gr.libelle}}
            <span v-if="gr.bloque" class="badge bg-danger rounded-pill">Liste noire</span>
            <span v-if="gr.abonnementAutomatique" class="badge bg-success rounded-pill ms-1">Abonnements</span>
          </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div v-for="ind in listeIndividuPresentation" :key="ind.id" class="card m-md-1 mb-3 p-3">
    <div class="row">
      <div class="col col-sm-4 col-md-3  ">
        <h5>
          <router-link :to='"/individu/"+ind.id'>{{ ind.pseudonyme }}</router-link>
        </h5>
        <img v-if="ind.imagePresentation"
             :src="ind.imagePresentation" style="width: 50px; height: 50px;" class=" rounded" alt="Image de profil">
        <i v-else class="fa-solid fa-user fa-3x"
           style="width: 50px; height: 50px; display: flex; justify-content: center; align-items: center;"
           aria-hidden="true"></i>
      </div>
      <div class="col-sm-8 col-md-6  limite-hauteur">
        <p class="card-text" v-html="ind.presentation"></p>
      </div>
      <div v-if="individuConnecteStore.individuConnecte && individuConnecteStore.individuConnecte.id !== ind.id"
           class="col  col-sm-1 col-md-1 ">
        <div class="dropdown m-2">
          <button type="button" class="btn btn-sm btn-primary"
                  data-bs-toggle="popover" data-bs-title="Affectez des groupes à cette personne"
                  :data-ind="JSON.stringify(ind)">
            Groupes <i class="fa fa-plus" aria-hidden="true"></i>
          </button>

        </div>
        <span v-if="bloque(ind)" class="badge bg-danger rounded-pill">Liste noire</span>
        <span v-if="abonnementAutomatique(ind)" class="badge bg-success rounded-pill">Abonnements</span>
      </div>
    </div>
  </div>

</template>


<script setup lang="ts">
import { useIndividuPresentationStore } from "@/store/individu-presentation-store";
import { createApp, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useIndividuConnecteStore } from "@/store/individu-connecte-store";
import { useGroupeIndividuStore } from "@/store/groupe-individu-store";
import { Popover, Tooltip } from 'bootstrap';
import AffectationIndividuGroupe from "@/composant/AffectationIndividuGroupe.vue";
import { GroupeIndividu, IndividuPresentation } from "@/classes/individu";

const individuPresentationStore = useIndividuPresentationStore()
const individuConnecteStore = useIndividuConnecteStore()
const groupeIndividuStore = useGroupeIndividuStore()
const listeIndividuPresentation = ref<IndividuPresentation[] | null>(null);
const popovers = ref<Popover[]>([])

const groupeSelectionne = ref<GroupeIndividu | null>(null)




const selectionnerGroupe = async (gr :GroupeIndividu|null) => {
  groupeSelectionne.value = gr
  if (groupeSelectionne.value !== null) {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee(groupeSelectionne.value);
  } else {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee()
  }
  await initialisationPopover()
}

const bloque = (individu: IndividuPresentation) => {
  if (groupeIndividuStore.listeGroupeIndividu != null) {
    return groupeIndividuStore.listeGroupeIndividu
      .filter(groupe => groupe.bloque === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === individu.id));
  }
}
const abonnementAutomatique = (individu: IndividuPresentation) => {
  if (groupeIndividuStore.listeGroupeIndividu != null) {
    return groupeIndividuStore.listeGroupeIndividu
      .filter(groupe => groupe.abonnementAutomatique === true)
      .some(groupe => groupe.listeMembre.some(ind => ind.id === individu.id));
  }
}

const initialisationPopover = async () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

  await nextTick(() => {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => {
      const indJson = popoverTriggerEl.getAttribute('data-ind');
      if (indJson) {
        const ind = IndividuPresentation.fromJson(JSON.parse(indJson));
        const popoverContentEl = document.createElement('div');
        const app = createApp(AffectationIndividuGroupe, { individu: ind });
        app.mount(popoverContentEl);

        const popover = new Popover(popoverTriggerEl, {
          html: true,
          content: popoverContentEl,
          trigger: "manual"
        });
        popovers.value.push(popover);

        let popoverTimeout: number;

        // Fonction pour démarrer ou prolonger le délai
        const startPopoverTimer = () => {
          clearTimeout(popoverTimeout); // Annule tout timer actif
          popoverTimeout = setTimeout(() => popover.hide(), 3000); // Fermeture après 3 secondes
        };

        // Ouvre le popover et démarre le délai lors du clic sur le déclencheur
        popoverTriggerEl.addEventListener('click', () => {
          clearTimeout(popoverTimeout);
          popover.show();
          startPopoverTimer();
        });

        // Prolonge le délai lorsqu'on clique dans le contenu du popover
        popoverContentEl.addEventListener('click', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        popoverContentEl.addEventListener('over', () => {
          startPopoverTimer(); // Redémarre le timer pour prolonger l'affichage
        });

        // Nettoie le timer si le popover est fermé manuellement
        popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
          clearTimeout(popoverTimeout);
        });
      }

      // Initialiser les tooltips sur les boutons
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, { trigger: 'focus' }));
    });
  });
};

onMounted(async () => {
  await individuConnecteStore.connecterIndividu()
  await groupeIndividuStore.rechercherListe()
  if (individuConnecteStore.individuConnecte) {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPrivee()
  } else {
    listeIndividuPresentation.value = await individuPresentationStore.donneListeIndividuPresentationPublique()
  }

  await initialisationPopover()

  // Nettoyer lors du démontage du composant
  onBeforeUnmount(() => {
    popovers.value.forEach(popover => popover.dispose()); // Dispose des popovers
  });

})

</script>

<style scoped lang="scss">
.limite-hauteur {
  max-height: 3em; /* Limite de hauteur */
  overflow: hidden; /* Cache tout ce qui dépasse */
}
</style>